import React from "react";
import { useTranslation } from "react-i18next";

// Material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";

// Material UI Icons
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import Team from "./sections/Team";

function AboutUs() {
  const { t } = useTranslation();

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: t("aboutUs.contact"), // Translated
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/besomeone.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {t("aboutUs.title")} {/* Translated */}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {t("aboutUs.description")} {/* Translated */}
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { dark } }) => dark.main }}
              onClick={() => (window.location.href = "mailto:cristofheralvarado@aol.com")}
            >
              {t("aboutUs.contact")} {/* Translated */}
            </MKButton>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              {t("aboutUs.findMeOn")} {/* Translated */}
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              {/* Instagram */}
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.instagram.com/calvarado_96/"
                mr={3}
              >
                <InstagramIcon fontSize="large" />
              </MKTypography>
              {/* GitHub */}
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://github.com/Kingwizard96"
                mr={3}
              >
                <GitHubIcon fontSize="large" />
              </MKTypography>
              {/* LinkedIn */}
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.linkedin.com/in/cristofher-alvarado-370891254/"
              >
                <LinkedInIcon fontSize="large" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Box sx={{ my: 4 }}>
          <Grid container spacing={4} alignItems="center">
            {/* License Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center" }}>
                <MKTypography variant="h4" mb={2}>
                  {t("aboutUs.licenseTitle")} {/* Translated */}
                </MKTypography>
                <img
                  src="/images/license.png" // Direct path from public/images
                  alt={t("aboutUs.licenseTitle")} // Translated
                  style={{
                    maxWidth: "60%", // Restrict image width to container
                    height: "auto", // Maintain aspect ratio
                    borderRadius: "8px", // Rounded corners
                  }}
                />
              </Box>
            </Grid>

            {/* About Me Section */}
            <Grid item xs={12} md={6}>
              <Box>
                <MKTypography variant="h4" mb={2}>
                  {t("aboutUs.aboutMe.title")}
                </MKTypography>
                <MKTypography variant="h6" mb={2}>
                  {t("aboutUs.aboutMe.description")} {/* Translated */}
                </MKTypography>
                <MKTypography variant="body1" color="inherit" mb={4}>
                  {t("aboutUs.aboutMe.moreAboutMe.title")} {/* Translated */}
                </MKTypography>
                <MKTypography variant="h6" color="info" mb={2}>
                  {t("aboutUs.aboutMe.moreAboutMe.description")} {/* Translated */}
                </MKTypography>
                <MKTypography variant="h6" color="inherit" mb={2}>
                  {t("aboutUs.aboutMe.experienceAndExpertise.title")} {/* Translated */}
                </MKTypography>
                <MKTypography variant="body1" color="info" mb={4}>
                  {t("aboutUs.aboutMe.experienceAndExpertise.description")}
                </MKTypography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Team />
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
