import React, { useState } from "react";
import { Button, Menu, MenuItem, Box } from "@mui/material"; // Import Material-UI components
import { useTranslation } from "react-i18next";

const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language
    handleMenuClose(); // Close the menu after selection
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "20px",
        left: "10px",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        onClick={handleMenuOpen}
        sx={{
          color: "black",
          textTransform: "capitalize",
          fontSize: ".85rem",
        }}
      >
        🌐 Language
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          },
        }}
      >
        <MenuItem onClick={() => changeLanguage("en")}>🇺🇸 English</MenuItem>
        <MenuItem onClick={() => changeLanguage("es")}>🇪🇸 Español</MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageToggle;
