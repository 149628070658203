import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalTeamCard from "UI/Cards/TeamCards/HorizontalTeamCard";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

function Team() {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={8}
      px={{ xs: 3, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white" gutterBottom>
              {t("aboutUs.team.title")} {/* Use translated text */}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              {t("aboutUs.team.description")} {/* Use translated text */}
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={3}>
              {/* Main profile card */}
              <HorizontalTeamCard
                image="/images/me.jpg"
                name={t("aboutUs.team.members.name")}
                position={{
                  color: "info",
                  label: t("aboutUs.team.members.title"),
                }}
                description={t("aboutUs.team.members.description")} // Translate the description
              />
            </MKBox>
          </Grid>

          {/* Company/Partner Logos */}
          <Grid item xs={12} lg={6}>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              {/* Protect logo with a white background */}
              <MKBox
                sx={{
                  backgroundColor: "white",
                  padding: "20px", // Increased padding for a larger background area
                  borderRadius: "8px",
                  boxShadow: 2, // Optional: adds some shadow around the logo
                  display: "inline-block", // Ensures the white background wraps the image
                }}
              >
                <img
                  src="/logos/protect.png"
                  alt="Protect Logo"
                  style={{ height: "120px", width: "auto" }} // Adjust height and auto width for a better fit
                />
              </MKBox>

              {/* Renovare logo with the same effect */}
              <MKBox
                sx={{
                  backgroundColor: "white",
                  padding: "20px", // Increased padding for a larger background area
                  borderRadius: "8px",
                  boxShadow: 2, // Optional: adds some shadow around the logo
                  display: "inline-block", // Ensures the white background wraps the image
                  marginLeft: "20px", // Add space between the logos
                }}
              >
                <img
                  src="/logos/renovare.png"
                  alt="Renovare Logo"
                  style={{ height: "120px", width: "auto" }} // Adjust height and auto width for a better fit
                />
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
