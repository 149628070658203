import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"; // Import the language detector
import translationEN from "./translations/en.json";
import translationES from "./translations/es.json";

const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
};

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // Fallback if browser language isn't available
    interpolation: {
      escapeValue: false, // React already handles XSS protection
    },
    detection: {
      order: ["navigator", "localStorage", "cookie", "htmlTag", "path", "subdomain"], // Order of language detection
      caches: ["localStorage", "cookie"], // Store the user's language preference
    },
  });

export default i18n;
