import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import "./App.css";
import routes from "routes"; // Ensure this is correctly imported
import LanguageToggle from "UI/LanguageToggle"; // Make sure this import path is correct
import { Box } from "@mui/material"; // Import Box for positioning

const Home = lazy(() => import("layouts/pages/presentation"));

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<div>Loading...</div>}>
        {/* Navbar (If you have one) */}
        {/* Add other components like your navbar here */}

        {/* Main App Content */}
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Language Toggle placed discreetly below the main content */}
        <Box sx={{ position: "absolute", bottom: 20, left: 20 }}>
          <LanguageToggle /> {/* Position this wherever you want */}
        </Box>
      </Suspense>
    </ThemeProvider>
  );
}
