import React from "react";
import { useTranslation } from "react-i18next";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";
import routes from "routes";
import footerRoutes from "footer.routes";
import "App.css";

function Clinics() {
  const { t } = useTranslation();

  // Extract clinic and hospital data from translations
  const clinics = t("clinics.clinics", { returnObjects: true });
  const clinicsTitle = t("clinics.title");
  const hospitals = t("clinics.hospitals", { returnObjects: true });
  const hospitalsTitle = t("clinics.hospitalsTitle");

  return (
    <>
      {/* Navbar Section */}
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <DefaultNavbar routes={routes} />
      </MKBox>

      {/* Main Content Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/bg-about-us.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        {/* Clinics Section */}
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          sx={{
            width: "100%",
            maxWidth: "1200px",
            marginBottom: "2rem",
          }}
        >
          <MKTypography variant="h4" color="white" gutterBottom>
            {clinicsTitle}
          </MKTypography>
          <MKBox
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={2}
            sx={{ width: "100%", margin: "0 auto" }}
          >
            {clinics.map((clinic, index) => (
              <MKBox
                key={index}
                my={2}
                textAlign="center"
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  flex: "1 1 200px",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <MKTypography variant="h5" color="black">
                    {clinic.name}
                  </MKTypography>
                  <MKTypography
                    variant="body1"
                    color="black"
                    sx={{
                      margin: "10px 0",
                      display: "block",
                    }}
                  >
                    {clinic.description}
                  </MKTypography>
                </div>
                <img
                  src={clinic.logo}
                  alt={`${clinic.name} logo`}
                  style={{ width: "100px", marginTop: "10px" }}
                />
              </MKBox>
            ))}
          </MKBox>
        </MKBox>

        {/* Hospitals Section */}
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          sx={{
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          <MKTypography variant="h4" color="white" gutterBottom>
            {hospitalsTitle}
          </MKTypography>
          <MKBox
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            gap={2}
            sx={{ width: "100%", margin: "0 auto" }}
          >
            {hospitals.map((hospital, index) => (
              <MKBox
                key={index}
                my={2}
                textAlign="center"
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  flex: "1 1 300px",
                  height: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <MKTypography variant="h5" color="black">
                  {hospital.name}
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="black"
                  sx={{
                    margin: "10px 0",
                    display: "block",
                  }}
                >
                  {hospital.description}
                </MKTypography>
                {/* Hospital Logo */}
                {hospital.logo && (
                  <img
                    src={hospital.logo}
                    alt={`${hospital.name} logo`}
                    style={{
                      width: "120px",
                      height: "auto",
                      margin: "10px auto 0",
                    }}
                  />
                )}
              </MKBox>
            ))}
          </MKBox>
        </MKBox>
      </MKBox>

      {/* Footer Section */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Clinics;
